import React from 'react'
import { Link } from 'gatsby'

import './topic.scss'

function Topic({ title, text, icon, link, tag }) {
  return (
    <Link to={link} className="topic">
      <div className="topic__icon" aria-hidden="true">
        {icon}
      </div>
      <h2 className="heading-3 topic__title ">{title}</h2>
      {tag && 
        <span className="tag margin-t-sm">{tag}</span>
      }
      <p
        className="list-text topic__text"
        dangerouslySetInnerHTML={{ __html: text }}
      ></p>
    </Link>
  )
}

export default Topic
