import React from 'react'
import Main from '../components/main'
import Teasers from '../components/teasers'
import ContentWrapper from '../components/contentWrapper'
import '../styles/patterns/largeHeader.scss'
import Breadcrumbs from '../components/breadcrumbs'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
import { FiBriefcase, FiHome, FiShield, FiArrowRight } from 'react-icons/fi'
import Topic from '../components/topic'
import Seo from '../components/seo'
import Img from 'gatsby-image'

const CategoryPage = ({ doc, topics, teasers, featuredTeasers, crumbs }) => {
  const topicComponents = topics.map((node, index) => {

    if (node.link === null || node.title === null) return null

    let icon
    switch (node.slug) {
      case 'entreprenorsinformation':
        icon = <FiBriefcase />
        break
      case 'bostad-och-fritid':
        icon = <FiHome />
        break
      case 'inpasseringskontroll':
        icon = <FiShield />
        break
      default:
        icon = <FiArrowRight />
    }

    return <Topic {...node} key={index} icon={icon} />
  })

  return (
    <Main>
      <Seo
        title={doc.metaTitle}
        description={doc.metaDescription}
        ogType="website"
        url={doc.path}
      />
      <div
        className={
          doc.topImage ? 'large-header large-header--bg' : 'large-header'
        }
      >
        <div className="large-header__inner">
          <ContentWrapper modifier="wide">
            <div className="flex-center-column">
              <h1 className="heading-1 heading-1--larger heading-1--blue">
                {doc.heading}
              </h1>
              {doc.introduction && (
                <div className="list-text list-text--larger">
                  <p>{doc.introduction}</p>
                </div>
              )}
            </div>
          </ContentWrapper>
        </div>
        {doc.topImage && (
          <ParallaxProvider>
            <div className="large-header__overlay"></div>
            <Parallax
              className="large-header__parallax"
              y={[-20, 20]}
              tagOuter="div"
            >
              <Img
                fluid={doc.topImage}
                placeholderClassName="large-header__bg lazy"
                style={{
                  position: 'absolute',
                  left: 0,
                  top: '-40px',
                  bottom: '-40px',
                  right: 0,
                }}
              />
            </Parallax>
          </ParallaxProvider>
        )}
      </div>
      <ContentWrapper modifier="wide">
        <Breadcrumbs crumbs={crumbs} currentPage={doc.heading} />
        <Teasers teasers={featuredTeasers} modifier="dark" />
        <div className="topics">
          {topicComponents}
          <div className="topics__placeholder"></div>
        </div>
        <Teasers teasers={teasers} />
      </ContentWrapper>
    </Main>
  )
}
export default CategoryPage
