import React from 'react'
import { graphql } from 'gatsby'
import CategoryPage from './categoryPage'
import linkResolver from '../../contentful/linkResolver'
import localizationFilter from '../../contentful/localizationFilter'

const CategoryPageContainer = ({ data, pageContext }) => {
  const { contentfulCategoryPage } = data
  const { breadcrumbs } = pageContext

  const categoryPage = {
    _doc: contentfulCategoryPage,
    path: linkResolver(contentfulCategoryPage),
    locale: contentfulCategoryPage.node_locale,
    heading: contentfulCategoryPage.heading,
    metaTitle:
      contentfulCategoryPage.metaTitle || contentfulCategoryPage.heading,
    metaDescription: contentfulCategoryPage.metaDescription,
    introduction: contentfulCategoryPage.introduction
      ? contentfulCategoryPage.introduction.introduction
      : null,
    topImage: contentfulCategoryPage.topImage
      ? contentfulCategoryPage.topImage.fluid
      : null
  }

  let topics = []
  if (contentfulCategoryPage.childPages) {
    topics = contentfulCategoryPage.childPages
      .filter(localizationFilter)
      .map(node => {
        return {
          title: node.heading,
          text: node.listText ? node.listText.listText : null,
          link: linkResolver(node),
          tag: node.listTag
        }
      })
  }

  let teasers = []
  if (contentfulCategoryPage.teasers) {
    teasers = contentfulCategoryPage.teasers.map(node => {
      return {
        title: node.title,
        linkText: node.linkText,
        text: node.text ? node.text.text : null,
        link: linkResolver(node.link)
      }
    })
  }

  let featuredTeasers = []
  if (contentfulCategoryPage.featuredTeasers) {
    featuredTeasers = contentfulCategoryPage.featuredTeasers.map(node => {
      return {
        title: node.title,
        linkText: node.linkText,
        text: node.text ? node.text.text : null,
        link: linkResolver(node.link)
      }
    })
  }

  return (
    <CategoryPage
      doc={categoryPage}
      topics={topics}
      teasers={teasers}
      featuredTeasers={featuredTeasers}
      crumbs={breadcrumbs}
    />
  )
}

export default CategoryPageContainer

export const pageQuery = graphql`
  query($locale: String!, $slug: String!) {
    contentfulCategoryPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      node_locale
      slug
      heading
      metaTitle
      metaDescription
      introduction {
        introduction
      }
      topImage {
        fluid(maxWidth: 2000, maxHeight: 500, quality: 80) {
          ...GatsbyContentfulFluid
        }
      }
      childPages {
        ...ContentfulLink
        ...ListInfo
      }
      teasers {
        ...Teasers
      }
      featuredTeasers {
        ...Teasers
      }
    }
  }
`
